import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

// import {
//     AuthLayout,
//     MainLayout,
// } from '../screens';

import AuthLayout from '../layout/auth';
import MainLayout from '../layout';
import Dashboard from '../pages/dashboard';
import Login from '../pages/auth/login';
import StoreForm from '../pages/store/form';
import BranchView from '../pages/branch/view';
import Profile from '../pages/user_profile';
import ProfileForm from '../pages/user_profile/form';
import ChangePasswordForm from '../pages/user_profile/changePassword';
import Stores from '../pages/store';
import Packages from '../pages/package';
import PackageForm from '../pages/package/form';
import PackageView from '../pages/package/view';
import StorePermissions from '../pages/store/permission';
import { Accounts } from '../pages/accounts';
import StoreView from '../pages/store/view';
import StoreLedger from '../pages/store/ledger';
import Blogs from '../pages/blogs';
import BlogForm from '../pages/blogs/form';
import Newsletters from '../pages/newsletter';
import NewsletterForm from '../pages/newsletter/form';
import Features from '../pages/features';
import FeatureForm from '../pages/features/form';
import StoreFeatures from '../pages/store/features';
import CuisineForm from '../pages/cuisine/form';
import Cuisines from '../pages/cuisine';
import Industries from '../pages/industry';
import IndustryForm from '../pages/industry/form';
import IncompleteRegistration from '../pages/incomplete_registration';
import NewslettersSubscribers from '../pages/newsletter/subscribers';
import OwnerForm from '../pages/store/owner_form';
import Transactions from '../pages/transaction';
import StoreLimits from '../pages/store/limits';
import Permissions from '../pages/permissions';
import PermissionForm from '../pages/permissions/form';

const AuthRedirect = ({ destination }) => {
    return <Route render={(props) => (<Redirect to={{ pathname: destination, state: { from: props.location } }} />)} />;
}

const wrapper = (Layout, Component) => {

    let accessToken = localStorage.getItem('token');

    const redirect = <AuthRedirect destination={'/login'}></AuthRedirect>

    if (accessToken == null) return redirect;

    return (
        <Layout>
            <Component />
        </Layout>
    )
}

export default function Navigation() {
    return (
        <Router>
            <Route exact path={`/login`} component={() => <AuthLayout><Login /></AuthLayout>} />
            <Route exact path={`/profile`} component={() => wrapper(MainLayout, Profile)} />
            <Route exact path={`/profile/form/:id?`} component={() => wrapper(MainLayout, ProfileForm)} />
            <Route exact path={`/profile/change-password/form/:id?`} component={() => wrapper(MainLayout, ChangePasswordForm)} />

            <Route exact path={`/`} component={() => wrapper(MainLayout, Dashboard)} />
            <Route exact path={`/stores`} component={() => wrapper(MainLayout, Stores)} />
            <Route exact path={`/stores/form/:id?`} component={() => wrapper(MainLayout, StoreForm)} />
            <Route exact path={`/branches/:store_id/view/:id?`} component={() => wrapper(MainLayout, BranchView)} />
            <Route exact path={`/stores/view/:id?`} component={() => wrapper(MainLayout, StoreView)} />
            <Route exact path={`/stores/view/limits/:id?`} component={() => wrapper(MainLayout, StoreLimits)} />
            <Route exact path={`/stores/ledger/:id?`} component={() => wrapper(MainLayout, StoreLedger)} />
            <Route exact path={`/stores/:id?/permissions`} component={() => wrapper(MainLayout, StorePermissions)} />
            <Route exact path={`/stores/:id?/features`} component={() => wrapper(MainLayout, StoreFeatures)} />
            <Route exact path={`/packages`} component={() => wrapper(MainLayout, Packages)} />
            <Route exact path={`/packages/form/:id?`} component={() => wrapper(MainLayout, PackageForm)} />
            <Route exact path={`/packages/view/:id?`} component={() => wrapper(MainLayout, PackageView)} />
            <Route exact path={`/accounts`} component={() => wrapper(MainLayout, Accounts)} />
            <Route exact path={`/permissions`} component={() => wrapper(MainLayout, Permissions)} />
            <Route exact path={`/permissions/form/:id?`} component={() => wrapper(MainLayout, PermissionForm)} />
            <Route exact path={`/blogs`} component={() => wrapper(MainLayout, Blogs)} />
            <Route exact path={`/blogs/form/:id?`} component={() => wrapper(MainLayout, BlogForm)} />
            <Route exact path={`/newsletters`} component={() => wrapper(MainLayout, Newsletters)} />
            <Route exact path={`/newsletters/form/:id?`} component={() => wrapper(MainLayout, NewsletterForm)} />
            <Route exact path={`/features`} component={() => wrapper(MainLayout, Features)} />
            <Route exact path={`/features/form/:id?`} component={() => wrapper(MainLayout, FeatureForm)} />
            <Route exact path={`/cuisines`} component={() => wrapper(MainLayout, Cuisines)} />
            <Route exact path={`/cuisines/form/:id?`} component={() => wrapper(MainLayout, CuisineForm)} />
            <Route exact path={`/industries`} component={() => wrapper(MainLayout, Industries)} />
            <Route exact path={`/industries/form/:id?`} component={() => wrapper(MainLayout, IndustryForm)} />
            <Route exact path={`/incomplete-registration`} component={() => wrapper(MainLayout, IncompleteRegistration)} />
            <Route exact path={`/newsletters-subscriber`} component={() => wrapper(MainLayout, NewslettersSubscribers)} />
            <Route exact path={`/stores/:storeId?/owner/form/:id?`} component={() => wrapper(MainLayout, OwnerForm)} />
            <Route exact path={`/transactions`} component={() => wrapper(MainLayout, Transactions)} />
        </Router>
    );
}