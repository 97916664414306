export const DOMAIN = process.env.REACT_APP_API_ENDPOINT;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;

export const PAGINATION_OPTIONS = {
    defaultPageSize: 50,
    showSizeChanger: true,
    pageSizeOptions: ['10', '25', '50', '100', '200', '500', '1000']
}

