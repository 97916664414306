import React, { useEffect, useState } from 'react';
import { Col, Row, Card, Button, Statistic, Divider, Space, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { getStore } from '../../data';
import windowSize from 'react-window-size';
import Branch from '../branch';
import { useParams } from "react-router-dom"
import { ArrowLeftOutlined } from '@ant-design/icons';

const { Title } = Typography

function StoreView({ windowWidth }) {

  let history = useHistory();
  const isMobile = windowWidth <= 768;
  let { id } = useParams()

  const [store, setStore] = useState({});
  const [user, setUser] = useState({});

  const initialize = async () => {
    const data = await getStore(id);
    if (data) {
      setStore(data);
      if (data.user) {
        setUser(data.user)
      }
    }
  }

  useEffect(() => {
    initialize();
  }, [])

  const renderStoreOwner = () => (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title level={3}>Store Owner</Title>
        <Button type="primary" onClick={() => history.push({ pathname: '/stores' })} icon={<ArrowLeftOutlined />}>Back To Stores</Button>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
        <div style={{ order: 0, width: '250%' }}>
          <Row gutter={16} >
            <Col span={6}>
              <Statistic title="Name" value={user.name} valueStyle={{ fontSize: 18 }} />
            </Col>
            <Col span={6}>
              <Statistic title="Email" value={user.email} valueStyle={{ fontSize: 18 }} />
            </Col>
            <Col span={4}>
              <Statistic title="Phone" value={user.phone} valueStyle={{ fontSize: 18 }} />
            </Col>
            <Col span={4}>
              <Statistic title="Gender" value={user.gender} valueStyle={{ fontSize: 18 }} />
            </Col>
            <Col span={4}>
              <Statistic title="City" value={user.city} valueStyle={{ fontSize: 18 }} />
            </Col>
          </Row>
        </div>
        <div style={{ order: 1 }}>
          <Button type="primary" onClick={() => history.push({ pathname: `/stores/${id}/owner/form/` + user.id })}>Edit Owner</Button>
        </div>
      </div>
    </>
  )

  const renderStoreDetails = () => (
    <Row gutter={16} >
      <Col span={24}>
        <Title level={3}>Store Details</Title>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ order: 0, width: '250%' }}>
            <Row>
              <Col span={6}>
                <Statistic title="Store Name" value={store.name} valueStyle={{ fontSize: 18 }} />
              </Col>
              <Col span={10}>
                <a href={store.domain} target={'_blank'}> <Statistic title="Store Website" value={store.domain} valueStyle={{ fontSize: 18 }} /></a>
              </Col>
              <Col span={8}>
                <Statistic title="Slogan" value={store.slogan} valueStyle={{ fontSize: 18 }} />
              </Col>
              <Col span={24}>
                <Statistic title="Description" value={store.description} valueStyle={{ fontSize: 18 }} />
              </Col>
            </Row>
          </div>
          <div style={{ order: 1 }}>
            <Space>
              <Button type="primary" onClick={() => history.push({ pathname: "/stores/ledger/" + id })}>View Ledger</Button>
              <Button type="primary" onClick={() => history.push({ pathname: "/stores/form/" + id })}>Edit Store</Button>
              <Button type="primary" onClick={() => history.push({ pathname: '/stores/' + id + '/features' })}>Features</Button>
              <Button type="primary" onClick={() => history.push({ pathname: '/stores/view/limits/' + id })}>Limits</Button>
              {/* <Button onClick={() => history.push({ pathname: '/stores/' + id + '/permissions' })}>Permissions</Button> */}
            </Space>
          </div>
        </div>
      </Col>
    </Row >
  )

  const renderStore = () => (
    <Card title="" bordered={false}>
      {renderStoreOwner()}
      <Divider />
      {renderStoreDetails()}
      <Divider />
      <Branch branches={store.branches} store_id={id} />
    </Card>
  );

  const renderStoreMobile = () => {
    // if (stores.length == 0) {
    //   return (
    //     <Card bordered={false} >
    //       <center>
    //         <Button type="primary" size="medium" icon={<PlusOutlined />}
    //           onClick={() => history.push({ pathname: "/stores/form/0" })}>
    //           Add Store
    //         </Button>
    //       </center>
    //     </Card>
    //   )
    // }
    // else {
    //   return (
    //     <>
    //       {
    //         stores.map(store => {
    //           return (
    //             <>
    //               <Card
    //                 title={store.name}
    //                 actions={[
    //                   <Space size='small'>
    //                     <Button icon={<EditOutlined />} type="primary" size="small" onClick={() => history.push({ pathname: "/stores/form/" + store.id })}>
    //                       Edit Store
    //                     </Button>

    //                     <Button icon={<BranchesOutlined />} type="primary" size="small" onClick={() => viewBranches(store.id)}>
    //                       Branches
    //                     </Button>
    //                   </Space>]}
    //                 style={{ width: '100%' }}>
    //                 <Text style={{ fontWeight: 'bolder' }}>Slogan : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{store.slogan}</span></Text>
    //                 <br />
    //                 <Text style={{ fontWeight: 'bolder' }}>Store Website :
    //                   <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
    //                     <a href={store.domain} target="_blank">{store.domain}</a>
    //                   </span></Text>
    //                 <br />
    //                 <Text style={{ fontWeight: 'bolder' }}>Description : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{store.description}</span></Text>
    //               </Card>
    //               <br />
    //             </>
    //           );
    //         })
    //       }
    //     </>

    //   );
    // }
  }

  const renderContent = () => {
    if (isMobile) {
      return renderStoreMobile()
    } else {
      return renderStore()
    }
  }

  return (
    <>
      {renderContent()}
    </>
  );



}

export default windowSize(StoreView)