import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { getRelativePosition } from 'chart.js/helpers';
import { Button, Space } from 'antd';

const Graph = ({
    activeStoreChart,
    incompleteRegistrationChart,
    activeDemoUsersChart,
    openTicketsChart,
    featureRequestsChart,
    newsletterSubscribersChart,
    months,
    handleClick
}) => {

    const canvasRef = useRef(null);
    const chartContainerRef = useRef(null);

    useEffect(() => {
        if (canvasRef.current) {
            const ctx = canvasRef.current.getContext('2d');

            const data = {
                labels: months,
                datasets: [
                    {
                        label: 'Total Active Stores',
                        data: [],
                        backgroundColor: 'Green',
                        borderColor: 'Green',
                        borderWidth: 1
                    },
                    {
                        label: 'Total Abandoned Stores',
                        data: [1, 11, 2, 5, 6, 8, 1, 12, 2, 5, 6, 8],
                        backgroundColor: 'Red',
                        borderColor: 'Red',
                        borderWidth: 1
                    },
                    {
                        label: 'Incomplete Registrations',
                        data: [],
                        backgroundColor: 'Orange',
                        borderColor: 'Orange',
                        borderWidth: 1
                    },
                    /*
                    {
                        label: 'Active Demo Users',
                        data: [],
                        backgroundColor: '#003366',
                        borderColor: '#003366',
                        borderWidth: 1
                    },
                    {
                        label: 'Open Tickets',
                        data: [],
                        backgroundColor: 'Purple',
                        borderColor: 'Purple',
                        borderWidth: 1
                    },
                    {
                        label: 'Feature Requests',
                        data: [],
                        backgroundColor: 'Teal',
                        borderColor: 'Teal',
                        borderWidth: 1
                    },
                    {
                        label: 'Total Active Newsletter Subscribers',
                        data: [],
                        backgroundColor: 'Brown',
                        borderColor: 'Brown',
                        borderWidth: 1
                    }
                    */
                ]
            };

            // Active Store
            const activeStoreData = activeStoreChart.map(item => item.total);
            const activeStoreMonths = activeStoreChart.map(item => `${item.month} ${item.year}`);
            const i = activeStoreMonths.map(month => months.indexOf(month));
            i.forEach((monthIndex, i) => {
                data.datasets[0].data[monthIndex] = activeStoreData[i];
            });

            // Incomplete Registration
            const incompleteRegistrationData = incompleteRegistrationChart.map(item => item.total);
            const incompleteRegistrationMonths = incompleteRegistrationChart.map(item => `${item.month} ${item.year}`);
            const x = incompleteRegistrationMonths.map(month => months.indexOf(month));
            x.forEach((monthIndex, i) => {
                data.datasets[1].data[monthIndex] = incompleteRegistrationData[i];
            });

            // Active Demo User
            const activeDemoUsersData = activeDemoUsersChart.map(item => item.total);
            const activeDemoUsersMonths = activeDemoUsersChart.map(item => `${item.month} ${item.year}`);
            const y = activeDemoUsersMonths.map(month => months.indexOf(month));
            y.forEach((monthIndex, i) => {
                data.datasets[2].data[monthIndex] = activeDemoUsersData[i];
            });

            /*
            // Open Ticket
            const openTicketsData = openTicketsChart.map(item => item.total);
            const openTicketsMonths = openTicketsChart.map(item => `${item.month} ${item.year}`);
            const z = openTicketsMonths.map(month => months.indexOf(month));
            z.forEach((monthIndex, i) => {
                data.datasets[4].data[monthIndex] = openTicketsData[i];
            });

            // Feature Requests Chart
            const featureRequestsData = featureRequestsChart.map(item => item.total);
            const featureRequestsMonths = featureRequestsChart.map(item => `${item.month} ${item.year}`);
            const j = featureRequestsMonths.map(month => months.indexOf(month));
            j.forEach((monthIndex, i) => {
                data.datasets[5].data[monthIndex] = featureRequestsData[i];
            });

            // Newsletters
            const newsletterSubscribersData = newsletterSubscribersChart.map(item => item.total);
            const newsletterSubscribersMonths = newsletterSubscribersChart.map(item => `${item.month} ${item.year}`);
            const k = newsletterSubscribersMonths.map(month => months.indexOf(month));
            k.forEach((monthIndex, i) => {
                data.datasets[6].data[monthIndex] = newsletterSubscribersData[i];
            });
            */

            const chart = new Chart(ctx, {
                type: 'line',
                data: data,
                options: {
                    onClick: (e) => {
                        const canvasPosition = getRelativePosition(e, chart);
                        // Substitute the appropriate scale IDs
                        const dataX = chart.scales.x.getValueForPixel(canvasPosition.x);
                        const dataY = chart.scales.y.getValueForPixel(canvasPosition.y);
                    },
                }
            });

            return () => {
                // Cleanup chart when component unmounts
                chart.destroy();
            };
        }
    }, [activeStoreChart, incompleteRegistrationChart, activeDemoUsersChart, openTicketsChart, featureRequestsChart, newsletterSubscribersChart, months]);

    const renderFilter = () => (
        <Space style={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={() => handleClick('1year')}>1 Year</Button>
            <Button onClick={() => handleClick('6month')}>6 Month</Button>
            <Button onClick={() => handleClick('1month')}>1 Month</Button>
            {/* <Button onClick={() => handleClick('1week')}>1 Week</Button> */}
        </Space>
    )

    return (
        <>
            <div ref={chartContainerRef} style={{ height: '550px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <canvas ref={canvasRef} id="myChart" />
            </div>
            <br />
            {renderFilter()}
        </>
    );
};

export default Graph;
